html {
  overflow: scroll;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

iframe {
  display: none;
}

.swiper {
  left: 125px;
  overflow: visible;
}

.MuiPaper-rounded, .MuiAutocomplete-paper {
  border-radius: 0 !important;
}

.MuiAccordion-root {
  box-shadow: none !important;
}

.MuiDivider-root {
  border-bottom-color: #3C3D3E !important;
  margin-bottom: 0 !important;
}
